import ApiService from "@/core/services/ApiService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"

export interface ResponseInfo {
  status: boolean
}

@Module
export default class VerificationModule
  extends VuexModule
  implements ResponseInfo
{
  status = false

  get getStatus() {
    return this.status
  }

  @Mutation
  [Mutations.SET_STATUS](status) {
    this.status = status
  }

  @Action
  [Actions.CHECK_MELI_CODE](credentials) {
    return ApiService.post("saveMeliCode", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_STATUS, data.status)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }

  @Action
  [Actions.ADD_BANK_CARD_NUMBER](credentials) {
    return ApiService.post("addBankCard", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_STATUS, data.status)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }
}
