import ApiService from "@/core/services/ApiService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"

export interface ResponseInfo {
  response: any
}

@Module
export default class RequestsModule extends VuexModule implements ResponseInfo {
  response

  get getResponse() {
    return this.response
  }

  @Mutation
  [Mutations.SET_RESPONSE](response) {
    this.response = response
  }

  @Action
  [Actions.WITHDRAW_PROFIT](payload) {
    return ApiService.post("profits/withdraw", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESPONSE, data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }

  @Action
  [Actions.DEPOSIT_PROFIT](payload) {
    return ApiService.post("profits/deposit", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_RESPONSE, data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }
}
