import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router"
import store from "@/store"
import { Mutations, Actions } from "@/store/enums/StoreEnums"
import JwtService from "@/core/services/JwtService"
import NProgress from "nprogress"
import "nprogress/nprogress.css"

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
      isAdmin: true,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/shop-categories/:id",
        name: "shop-categories",
        component: () => import("@/views/admin/shop-categories/categories.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/edit-categories/:id",
        name: "edit-categories",
        component: () =>
          import("@/views/admin/shop-categories/edit-categories.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/add-categories",
        name: "add-categories",
        component: () =>
          import("@/views/admin/shop-categories/add-categories.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/video-categories/:id",
        name: "video-categories",
        component: () =>
          import("@/views/admin/video-categorie/video-categories.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/add-video-categories",
        name: "add-video-categories",
        component: () =>
          import("@/views/admin/video-categorie/add-video-categories.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/edit-video-categories/:id",
        name: "edit-video-categories",
        component: () =>
          import("@/views/admin/video-categorie/edit-video-categories.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/masters",
        name: "master",
        component: () => import("@/views/admin/masters/masters.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/edit-master/:id",
        name: "edit-master",
        component: () => import("@/views/admin/masters/edit-master.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/add-master",
        name: "add-master",
        component: () => import("@/views/admin/masters/add-master.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/videos/add",
        name: "add-videos",
        component: () => import("@/views/admin/videos/Add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/videos",
        name: "videos",
        component: () => import("@/views/admin/videos/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/videos/edit/:id",
        name: "edit-videos",
        component: () => import("@/views/admin/videos/Edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/coupon",
        name: "coupon",
        component: () => import("@/views/admin/coupon/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/coupon/add",
        name: "add-coupon",
        component: () => import("@/views/admin/coupon/Add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/properties",
        name: "properties",
        component: () => import("@/views/admin/properties/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/properties/add",
        name: "add-properties",
        component: () => import("@/views/admin/properties/Add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/properties/edit/:id",
        name: "edit-properties",
        component: () => import("@/views/admin/properties/Edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/propertiesValue/:parent",
        name: "propertiesValue",
        component: () => import("@/views/admin/propertiesValue/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/propertiesValue/add/:parent",
        name: "add-propertiesValue",
        component: () => import("@/views/admin/propertiesValue/Add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/propertiesValue/edit/:id/:parent",
        name: "edit-propertiesValue",
        component: () => import("@/views/admin/propertiesValue/Edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/users",
        name: "users",
        component: () => import("@/views/admin/users/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/users/add",
        name: "users-add",
        component: () => import("@/views/admin/users/Add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/users/edit/:id",
        name: "users-edit",
        component: () => import("@/views/admin/users/Edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/factors",
        name: "factors",
        component: () => import("@/views/admin/factors/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/factors/edit/:id",
        name: "factors-edit",
        component: () => import("@/views/admin/factors/Edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/settings",
        name: "settings",
        component: () => import("@/views/admin/settings/index.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/packages",
        name: "packages",
        component: () => import("@/views/admin/packages/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/packages/add",
        name: "packages-add",
        component: () => import("@/views/admin/packages/Add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/packages/edit/:id",
        name: "packages-edit",
        component: () => import("@/views/admin/packages/Edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/department",
        name: "department",
        component: () => import("@/views/admin/Department/list.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/department/edit/:id",
        name: "department-edit",
        component: () => import("@/views/admin/Department/edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/department/add",
        name: "department-add",
        component: () => import("@/views/admin/Department/add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/factors",
        name: "factors",
        component: () => import("@/views/admin/factors/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/products",
        name: "products",
        component: () => import("@/views/admin/products/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/products/add",
        name: "products-add",
        component: () => import("@/views/admin/products/Add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/products/edit/:id",
        name: "products-edit",
        component: () => import("@/views/admin/products/Edit.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: true,
        },
      },
      {
        path: "/admin/tickets",
        name: "admin-tickets",
        component: () => import("@/views/admin/tickets/List.vue"),
        meta: {
          pageTitle: "تیکت ها",
          breadcrumbs: ["تیکت ها"],
          justAdmin: false,
        },
      },
      {
        path: "/admin/tickets/add/:user",
        name: "admin-tickets-add",
        component: () => import("@/views/admin/tickets/Add.vue"),
        meta: {
          pageTitle: "ارسال تیکت",
          breadcrumbs: ["ارسال تیکت", "تیکت ها"],
          justAdmin: false,
        },
      },
      {
        path: "/admin/ticket/:user/:id",
        name: "admin-ticket",
        component: () => import("@/views/admin/tickets/Ticket.vue"),
        meta: {
          pageTitle: "تیکت",
          breadcrumbs: ["تیکت", "تیکت ها"],
          justAdmin: false,
        },
      },
      {
        path: "/slideshow",
        name: "slideshow",
        component: () => import("@/views/admin/slideshow/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/slideshow/add",
        name: "add-slideshow",
        component: () => import("@/views/admin/slideshow/Add.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/accounting",
        name: "accounting",
        component: () => import("@/views/admin/accounting/List.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/accounting/:user/:id",
        name: "accounting-user",
        component: () => import("@/views/admin/accounting/Accounting.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
    ],
  },
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layouts/main-layout/MainLayout.vue"),
    meta: {
      middleware: "auth",
      isAdmin: false,
    },
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
      {
        path: "/wallet/withdraw",
        name: "wallet-withdraw",
        component: () => import("@/views/users/UserWithdrawal.vue"),
        meta: {
          pageTitle: "",
          breadcrumbs: [],
          justAdmin: false,
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/AuthLayout.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
        meta: {
          pageTitle: "ورود",
        },
      },
      {
        path: "/password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
        name: "password-reset",
        meta: {
          pageTitle: "در خواست تغییر رمز",
        },
      },
      {
        path: "/change-password/:mobile",
        component: () =>
          import(
            "@/views/crafted/authentication/basic-flow/ChangePassword.vue"
          ),
        name: "ChangePassword",
        meta: {
          pageTitle: "تغییر رمز عبور",
        },
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/layouts/SystemLayout.vue"),
    children: [
      {
        // the 404 route, when none of the above matches
        path: "/404",
        name: "404",
        component: () => import("@/views/crafted/authentication/Error404.vue"),
        meta: {
          pageTitle: "Error 404",
        },
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/crafted/authentication/Error500.vue"),
        meta: {
          pageTitle: "Error 500",
        },
      },
      {
        path: "/payment-canceled",
        name: "payment-canceled",
        component: () =>
          import("@/views/crafted/authentication/PaymentCanceled.vue"),
        meta: {
          pageTitle: "Payment Canceled",
        },
      },
      {
        path: "/payment-done/:refId",
        name: "payment-done",
        component: () =>
          import("@/views/crafted/authentication/PaymentDone.vue"),
        meta: {
          pageTitle: "Payment Done",
        },
      },
    ],
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
})

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  NProgress.set(0.1)

  // current page view title
  document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`

  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG)

  // verify auth token before each page change
  await store.dispatch(Actions.VERIFY_AUTH, { token: JwtService.getToken() })

  // before page access check if page requires authentication
  if (to.meta.middleware == "auth") {
    if (store.getters.isUserAuthenticated) {
      if (to.meta.isAdmin) {
        if (
          store.getters.currentUser.role !== 1 &&
          store.getters.currentUser.role !== 2
        ) {
          next({
            path: "/500",
          })
          return
        }
        if (typeof to.meta.justAdmin !== undefined && to.meta.justAdmin) {
          if (store.getters.currentUser.role !== 1) {
            next({
              path: "/500",
            })
            return
          }
        }
      }
      next()
    } else {
      next({ name: "sign-in" })
    }
  } else {
    next()
  }

  // Scroll page to top on every route change
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  })
})

router.afterEach(() => {
  setTimeout(() => NProgress.done(), 500)
})

export default router
