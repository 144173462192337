import ApiService from "@/core/services/ApiService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"

export interface ResponseInfo {
  status: boolean
}

@Module
export default class WalletModule extends VuexModule implements ResponseInfo {
  status = false

  amount = 0

  get getStatusAndAmount() {
    return {
      status: this.status,
      amount: this.amount,
    }
  }

  @Mutation
  [Mutations.SET_STATUS_AND_AMOUNT](data) {
    this.status = data.status
    this.amount = data.amount
  }

  @Action
  [Actions.CHECK_TRANSACTION_ID](credentials) {
    return ApiService.post("wallet/checkTransaction", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_STATUS_AND_AMOUNT, data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }
}
