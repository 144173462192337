import { createStore } from "vuex"
import { config } from "vuex-module-decorators"

import AuthModule from "@/store/modules/AuthModule"
import BodyModule from "@/store/modules/BodyModule"
import ConfigModule from "@/store/modules/ConfigModule"
import ThemeModeModule from "@/store/modules/ThemeModeModule"
import VerificationModule from "@/store/modules/VerificationModule"
import WalletModule from "@/store/modules/WalletModule"
import RequestsModule from "@/store/modules/RequestsModule"

config.rawError = true

const store = createStore({
  modules: {
    AuthModule,
    BodyModule,
    ConfigModule,
    ThemeModeModule,
    VerificationModule,
    WalletModule,
    RequestsModule,
  },
})

export default store
