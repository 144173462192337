import ApiService from "@/core/services/ApiService"
import JwtService from "@/core/services/JwtService"
import { Actions, Mutations } from "@/store/enums/StoreEnums"
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators"

export interface User {
  name: string
  surname: string
  email: string
  mobile: string
  password: string
  token: string
  role: number
}

export interface UserAuthInfo {
  errors: unknown
  user: User
  isAuthenticated: boolean
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = {}
  user = {} as User
  isAuthenticated = !!JwtService.getToken()

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = { ...error }
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true
    this.user = user
    this.errors = {}
    JwtService.saveToken(user.token)
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false
    this.user = {} as User
    this.errors = []
    JwtService.destroyToken()
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("login", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }

  @Action
  [Actions.LOGOUT]() {
    return ApiService.post("logout", {})
      .then(({ data }) => {
        this.context.commit(Mutations.PURGE_AUTH)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.PURGE_AUTH)
      })
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("register", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {})
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("forgot", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {})
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }

  @Action
  [Actions.CHANGE_PASSWORD](payload) {
    return ApiService.post("changePassword", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {})
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }

  @Action
  [Actions.ACTIVE_ACCOUNT](payload) {
    return ApiService.post("active", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, {})
        this.context.commit(Mutations.SET_AUTH, data)
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }

  @Action
  [Actions.RESEND_CODE](payload) {
    return ApiService.post("resend", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {})
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors)
      })
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {
    if (JwtService.getToken()) {
      ApiService.setHeader()
      return ApiService.post("checkLogin", {})
        .then(({ data }) => {
          data.token = payload.token
          this.context.commit(Mutations.SET_AUTH, data)
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors)
          this.context.commit(Mutations.PURGE_AUTH)
        })
    } else {
      return this.context.commit(Mutations.PURGE_AUTH)
    }
  }
  @Action
  [Actions.CLEAR_ERRORS](payload) {
    this.errors = {}
  }
}
